[class^="flag-icon-"], [class*="flag-icon-"] {
  display: inline-flex;
}

[class^="flag-icon-"]:before, [class*="flag-icon-"]:before {
  content: '';
  background-image: url('flags-sprite.svg');
  background-repeat: no-repeat;
  width: 30.8px;
  height: 20px;
}

.flag-icon-ch:before {
  background-position: -3.4px -5.6px;
}
.flag-icon-fr:before {
  background-position: -44px -5.6px;
}
.flag-icon-de:before {
  background-position: -84.6px -5.6px;
}
.flag-icon-it:before {
  background-position: -125.3px -5.6px;
}
.flag-icon-pl:before {
  background-position: -165.9px -5.6px;
}
.flag-icon-pt:before {
  background-position: -3.3px -34.1px;
}
.flag-icon-ru:before {
  background-position: -44px -34.1px;
}
.flag-icon-es:before {
  background-position: -84.7px -34.1px;
}
.flag-icon-ua:before {
  background-position: -125.3px -34.1px;
}
.flag-icon-en:before {
  background-position: -165.9px -34.1px;
}